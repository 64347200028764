import React from "react"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { FormattedMessage } from "react-intl"
import '../style/ptk.scoped.scss'
import { Link } from "gatsby"
const Ptk = ({ pageContext }) => {
  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: PTK"/>
      <div className="ptk-container">
        <div className="ptk-banner">
          <div className="product-banner-box">
            <h1 className="product-banner-title"><FormattedMessage id="ptk.pageTitle" /><i className="ptk"></i></h1>
            <div className="product-banner-desc">
              <p><FormattedMessage id="ptk.pageContent" /></p>
              <p><FormattedMessage id="ptk.pageContent2" /></p>
            </div>
            <div className="btn-group">
              <Link to={`${pagePrefix}/downloads/ptk/all/`} className="btn download"><FormattedMessage id="index.download" /></Link>
              <a href={`https://docs.mogdb.io/${pageLang}/ptk/`} rel="noreferrer" target="_blank" className="btn searchDoc"><FormattedMessage id="index.docs" /></a>
            </div>
          </div>
        </div>
        <div className="ptk-ability">
          <div className="title"><FormattedMessage id="ptk.abilityTitle" /></div>
          <div className="ability ability-1">
            <div>
              <div className="j_asciinema-player" data-cast="demo_v0_7_0"></div>
              <div className="ability-title"><FormattedMessage id="ptk.abilityTitle1" /></div>
              <div className="ability-desc"><FormattedMessage id="ptk.abilityDesc1" values={{br: () => <br></br>, span: chunks => <span className="mark">{chunks}</span>}} /></div>
            </div>
          </div>
          <div className="ability ability-2">
            <div>
              <div className="j_asciinema-player" data-cast="install_v0_7_0"></div>
              <div className="ability-title"><FormattedMessage id="ptk.abilityTitle2" /></div>
              <div className="ability-desc"><FormattedMessage id="ptk.abilityDesc2" values={{span: chunks => <span className="mark">{chunks}</span>}} /></div>
            </div>
          </div>
          <div className="ability ability-3">
            <div>
              <div className="j_asciinema-player" data-cast="scaleout_v0_7_0"></div>
              <div className="ability-title"><FormattedMessage id="ptk.abilityTitle3" /></div>
              <div className="ability-desc"><FormattedMessage id="ptk.abilityDesc3" values={{span: chunks => <span className="mark">{chunks}</span>}} /></div>
            </div>
          </div>
          <div className="ability ability-4">
            <div>
              <div className="j_asciinema-player" data-cast="upgrade_v0_7_0"></div>
              <div className="ability-title"><FormattedMessage id="ptk.abilityTitle4" /></div>
              <div className="ability-desc"><FormattedMessage id="ptk.abilityDesc4" values={{span: chunks => <span className="mark">{chunks}</span>}} /></div>
            </div>
          </div>
        </div>
        <div className="ptk-experience">
          <div>
            <div className="title"><FormattedMessage id="index.experience" /></div>
            <div className="content"><FormattedMessage id="ptk.experienceContent" /></div>
            <div className="btn-group">
              <a href={`https://docs.mogdb.io/${pageLang}/ptk/latest/release`} target="_blank" className="btn"><FormattedMessage id="index.useNow" /></a>
              <Link to={`${pagePrefix}/contact/`} className="btn download"><FormattedMessage id="index.contactUs" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Ptk